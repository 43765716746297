import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import Newsletter from "../components/newsletter"

class ManualPage extends React.Component {

  render() {          
    return (
      <Layout>
        <SEO title="Manual" />

        <section>
          <Container className="py-3 py-xl-5">
            <Row className="align-items-end">
              <Col xl="4">
                <h2 className="display-4 text-primary">Manual.</h2>
                <p className="lead mt-4">
                  Work in Progress.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <Newsletter />
      </Layout>
    )
  }
}

export default ManualPage